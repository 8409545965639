/* eslint-disable complexity */
import {User} from '@hconnect/apiclient'
import {useRolesBusinessLogic} from '@hconnect/common/Invite/hooks/useRoles'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {
  MOMENTJS_DATE_FORMAT,
  oneYear,
  Page,
  ResponsiveTable,
  Typography,
  dateRangeFormatter
} from '@hconnect/uikit'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import {Paper} from '@material-ui/core'
import {Grid, Slide} from '@mui/material'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import moment, {Moment} from 'moment'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {PageNames} from '../../../common/constants'
import {useContainerStyles} from '../../../common/styles/containerStyles'
import {BusinessLineType} from '../../../common/types'
import {useQueryParamState} from '../../../Hooks/useQueryParamState'
import {CustomerFeedbackBanner} from '../../../Molecules/CustomerFeedbackProgramme/'
import {FilterBarLink} from '../../../Molecules/FilterBarLink'
import {LoadMore} from '../../../Molecules/LoadMore'
import {
  ResponsiveHeight,
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../../Molecules/Responsive.utils'
import {ResultsForMessage} from '../../../Molecules/ResultsForMessage'
import {SMFilter} from '../../../Molecules/SMFilter/SMFilter'
import {useBranding} from '../../../Organisms/Branding'
import {CustomerStateType} from '../../../Organisms/Customers'
import {useFeaturesState} from '../../../Organisms/Features'
import {Plant} from '../../../Organisms/Plants/Plants.types'
import {AppState} from '../../../Root.store'
import {FilterBarErrorCertificate, Filters, MaterialCertificatesFilter} from '../Filters'

import CertificateColumns from './Cement.columns'
import {CementAside} from './CementAside'
import {CertificateGenerateButtons} from './CertificateGenerateButtons'
import {useMaterialCertificates, usePlants, useProducts} from './hooks'
import {GenerateCertificateRoute} from './LettersOfCertification/GenerateCertificate'
import {TestCertificatesCardList} from './TestCertificatesCardList'
import {MaterialCertificate, Product} from './types'
import {filterType, useTrackFilterEvents} from '../../../TrackEvents/hubFilterEvents'
import Content from '../../../Molecules/Content'

export const Cement: React.FC = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {classes: responsiveClasses} = useResponsiveGridStyles()
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const {classes} = useResponsiveGridStyles()
  const {trackDateFilterEvent, trackFilterEvents} = useTrackFilterEvents()
  const [smFilterVisible, setSmFilterVisible] = React.useState<boolean>(false)
  const [selectedRow, setSelectedRow] = React.useState<MaterialCertificate | undefined>(undefined)
  const [showSlide, setShowSlide] = React.useState<boolean>(false)
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const [initialRowOpened, setInitialRowOpened] = React.useState<boolean>(false)
  const {
    classes: {pageContainer}
  } = useContainerStyles()
  const [startDate, endDate] = oneYear(language)

  const [filter, setFilter] = useQueryParamState<MaterialCertificatesFilter>({
    materialName: undefined,
    startDate: startDate.format(MOMENTJS_DATE_FORMAT),
    endDate: endDate.format(MOMENTJS_DATE_FORMAT),
    skip: 0,
    limit: 10,
    plantId: undefined,
    customerId: undefined,
    sortedByKey: 'plantName',
    sortedByDirection: 'asc',
    open: false
  })

  const {selectedCustomer} = useSelector<AppState, CustomerStateType>((state) => state.customers)
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const {getFeature} = useFeaturesState()
  const showLoc = getFeature('LetterOfCertification')

  const handleDateChange = (dates: {startDate: Moment | null; endDate: Moment | null}) => {
    const dateRange = {
      startDate: dates.startDate?.format(MOMENTJS_DATE_FORMAT) || filter.startDate,
      endDate: dates.endDate?.format(MOMENTJS_DATE_FORMAT) || filter.endDate
    }
    setFilter({
      ...filter,
      ...dateRange
    })
    trackDateFilterEvent(PageNames.TEST_REPORTS, dateRange)
  }

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }

  const loadMore = () =>
    setFilter({
      ...filter,
      limit: filter.limit + 10
    })

  const getEmptyMessage = (loading: boolean) => (loading ? t('loading') : t('noData'))
  const {safetyDataSheetLink} = useBranding()
  const history = useHistory()
  const {data: plants, error: plantsError, isFetching: isPlantsFetching} = usePlants()
  const {data: products, error: productsError, isFetching: isProductsFetching} = useProducts(filter)

  const filterEventTracking = (type, value) => {
    trackFilterEvents({
      filterType: type,
      page: PageNames.TEST_REPORTS,
      ...value
    })
  }

  const handlePlants = (plant: Plant) => {
    const plantId = plant ? plant.plantId : undefined
    setFilter({
      ...filter,
      plantId
    })
    filterEventTracking(filterType.PLANTS, {plantId, cleared: !plantId})
  }
  const handleProducts = (product: Product) => {
    const materialName = product ? product.materialNumber : undefined
    setFilter({
      ...filter,
      materialName
    })
    filterEventTracking(filterType.PRODUCTS, {materialName, cleared: !materialName})
  }
  useEffect(() => {
    setShowSlide(!!selectedRow || showCustomerPanel)
  }, [selectedRow, showCustomerPanel])

  useEffect(() => {
    const [startDate, endDate] = oneYear(language)
    let filteredStartDate, filteredEndDate: string
    if (!filter.startDate || !filter.endDate) {
      filteredStartDate = startDate.format(MOMENTJS_DATE_FORMAT)
      filteredEndDate = endDate.format(MOMENTJS_DATE_FORMAT)
    } else {
      filteredStartDate = filter.startDate
      filteredEndDate = filter.endDate
    }
    setFilter({
      ...filter,
      customerId: selectedCustomer?.customerId,
      startDate: filteredStartDate,
      endDate: filteredEndDate
    })
  }, [])

  const navigateToGenerateCertificatePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    plantId: string,
    materialNumber: string
  ) => {
    event.stopPropagation()
    history.push(`${GenerateCertificateRoute}/?plantId=${plantId}&materialNumber=${materialNumber}`)
  }
  const columnConfig = CertificateColumns({
    navigateToGenerateCertificatePage,
    showLoc,
    startDate: filter.startDate,
    endDate: filter.endDate
  })
  const {isQCSupervisor} = useRolesBusinessLogic()
  const enabled: boolean = isQCSupervisor || !!selectedCustomer?.customerId

  const {
    data,
    isFetching: materialCertificatesFetching,
    error
  } = useMaterialCertificates(filter, enabled)
  const {total, items} = data || {items: []}
  const isFetching = materialCertificatesFetching || isPlantsFetching || isProductsFetching

  useEffect(() => {
    if (filter.open && data && data.items.length > 0 && !initialRowOpened) {
      setSelectedRow(data.items[0])
      setInitialRowOpened(true)
    }
  }, [filter.open, data, initialRowOpened])

  useEffect(() => {
    if (
      selectedRow &&
      !isFetching &&
      !items?.find((x) => x.plantIdUnique === selectedRow.plantIdUnique)
    ) {
      setSelectedRow(undefined)
    }
  }, [items, selectedRow, isFetching])

  const filterBarErrors: FilterBarErrorCertificate[] = []

  const showLoadMore = total && items && items.length ? total > items.length : false
  const resultsForMessage = [dateRangeFormatter(startDate, endDate, language), filter.plantId]

  if (error) {
    return null
  }
  if (!error && !isPlantsFetching && !isProductsFetching) {
    if (items && !(items.length > 0)) {
      filterBarErrors.push({key: 'empty', label: t('filterBar.errorEmpty')})
    }
  }
  const handleRowClick = (row?: MaterialCertificate) => {
    if (row) {
      setShowCustomerPanel(false)
      setSelectedRow(row)

      trackEvent('hubExpand', {
        product: 'hub',
        expandedItem: 'materialCertificate',
        expandedItemId: row.plantId,
        expandedItemBusinessLine: BusinessLineType.CEM,
        expandedItemMaterial: row.materialDescription,
        userId: user?.user_id || '',
        userCountry: user?.country
      })
    } else {
      setSelectedRow(undefined)
    }
  }
  const closeSidePanel = () => {
    if (screenSize !== 'md') {
      setShowCustomerPanel(false)
      setSelectedRow(undefined)
    } else {
      setShowSlide(false)
    }
  }

  return (
    <Content style={{padding: '4px 8px 8px 8px'}}>
      <div className={pageContainer}>
        <Page
          boxed={false}
          variant={largeScreen ? 'withDetails' : 'default'}
          {...{px: 0, py: 0}}
          data-test-id="certificate-cement-page"
          headerActionContent={
            <CertificateGenerateButtons
              materialCertificatesFetching={materialCertificatesFetching}
            />
          }
          title={
            filter.plantId
              ? t('certificate.title', {PLANT_NAME: filter.plantId})
              : t('certificate.titleDefault')
          }
        >
          <Grid
            container
            columnSpacing={2}
            style={{position: 'relative', minHeight: ResponsiveHeight}}
          >
            <Grid item xs={12} lg={8} height={!smallScreen ? ResponsiveHeight : 'initial'}>
              <Paper
                elevation={4}
                aria-label="Order list"
                className={classes.gridItem}
                style={{height: '100%', paddingBottom: 20}}
              >
                {!smallScreen ? (
                  <Filters
                    key="all-filters"
                    handleDates={handleDateChange}
                    startDate={moment(filter.startDate)}
                    endDate={moment(filter.endDate)}
                    errors={filterBarErrors}
                    plantsError={plantsError}
                    plants={plants}
                    selectedPlantId={filter.plantId as string}
                    handlePlants={handlePlants}
                    productsError={productsError}
                    products={products}
                    handleProducts={handleProducts}
                    selectedProductId={filter.materialName}
                  />
                ) : (
                  <SMFilter
                    filters={[
                      <Filters
                        key="all-filters"
                        handleDates={handleDateChange}
                        startDate={moment(filter.startDate)}
                        endDate={moment(filter.endDate)}
                        errors={filterBarErrors}
                        plantsError={plantsError}
                        plants={plants}
                        selectedPlantId={filter.plantId as string}
                        handlePlants={handlePlants}
                        productsError={productsError}
                        products={products}
                        handleProducts={handleProducts}
                        selectedProductId={filter.materialName}
                      />
                    ]}
                    isVisible={smFilterVisible}
                    setIsVisible={setSmFilterVisible}
                    numberOfItems={items?.length}
                    apply={() => setSmFilterVisible(false)}
                  />
                )}
                {safetyDataSheetLink && (
                  <FilterBarLink
                    linkAddress={safetyDataSheetLink}
                    t={t}
                    linkLabelTranslationKey="certificate.safetyDataSheets.title"
                  />
                )}

                {!error && !materialCertificatesFetching && (
                  <ResultsForMessage messageList={resultsForMessage} />
                )}

                {!error && (
                  <Box px={2}>
                    {!smallScreen ? (
                      <ResponsiveTable
                        data-test-id="certificate-data-table"
                        columns={columnConfig}
                        rows={items}
                        keyField="plantIdUnique"
                        loading={isFetching}
                        onSort={(e, sortKey: string) => {
                          setSortKey(sortKey)
                        }}
                        sortedBy={filter.sortedByKey}
                        sortingOrder={filter.sortedByDirection ? 'asc' : 'desc'}
                        emptyMessage={
                          <Typography variant="caption">{getEmptyMessage(isFetching)}</Typography>
                        }
                        onRowClick={handleRowClick}
                        rowHeight={58}
                        isRowSelectable={() => true}
                        rowDetailsAvailable
                        rowDetailsClosed={selectedRow === undefined}
                        promoBanner={
                          <CustomerFeedbackBanner
                            setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
                            entryPoint={PageNames.TEST_REPORTS}
                          />
                        }
                      />
                    ) : (
                      <TestCertificatesCardList
                        rows={items}
                        loading={isFetching}
                        keyField="plantIdUnique"
                        onCardClick={(event, row: MaterialCertificate) => {
                          handleRowClick(row)
                        }}
                        setShowCustomerPanel={setShowCustomerPanel}
                      />
                    )}
                  </Box>
                )}
                {showLoadMore && (
                  <LoadMore
                    isFetching={materialCertificatesFetching}
                    skip={filter.skip}
                    onLoadMoreClick={loadMore}
                    onLight
                  />
                )}
              </Paper>
            </Grid>

            {largeScreen && (
              <Grid
                item
                lg={4}
                height={ResponsiveHeight}
                data-test-id={'cement-certificate-details-rightAside'}
              >
                <Paper elevation={4} className={classes.gridItem} style={{height: '100%'}}>
                  <CementAside
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    certificateDetails={selectedRow}
                    setSelectedRow={setSelectedRow}
                    showCustomerPanel={showCustomerPanel}
                    close={closeSidePanel}
                  />
                </Paper>
              </Grid>
            )}

            {screenSize === 'md' && (selectedRow || showCustomerPanel) && (
              <Box
                position="absolute"
                right={0}
                top={0}
                width={smallScreen ? 1 : 1 / 3}
                height={ResponsiveHeight}
              >
                <Slide
                  direction="left"
                  in={Boolean(showSlide)}
                  onExited={() => {
                    setSelectedRow(undefined)
                    setShowCustomerPanel(false)
                  }}
                  mountOnEnter
                  unmountOnExit
                  className={classes.gridItem}
                >
                  <Paper elevation={4} style={{height: '100%'}}>
                    <CementAside
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                      certificateDetails={selectedRow}
                      setSelectedRow={setSelectedRow}
                      showCustomerPanel={showCustomerPanel}
                      close={closeSidePanel}
                    />
                  </Paper>
                </Slide>
              </Box>
            )}
          </Grid>
        </Page>
      </div>
      {smallScreen && (
        <Overlay
          data-test-id="sm-details-opened"
          isVisible={!!selectedRow || showCustomerPanel}
          overlayClassName={responsiveClasses.overlay}
          contentClassName={classNames(
            responsiveClasses.overlayContent,
            responsiveClasses.gridItem
          )}
        >
          <CementAside
            startDate={filter.startDate}
            endDate={filter.endDate}
            certificateDetails={selectedRow}
            setSelectedRow={setSelectedRow}
            showCustomerPanel={showCustomerPanel}
            close={closeSidePanel}
            smallScreen
          />
        </Overlay>
      )}
    </Content>
  )
}
